//import ReactPixel from "react-facebook-pixel"
 
const key_pixel = process.env.REACT_APP_KEY_PIXEL_FACEBOOK
 
function init() {
  // Enable debug mode on the local development environment
 // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
 //ReactPixel.init(key_pixel)
  if (typeof window !== "undefined") {
      if (window.fbq != null) { 
        window.fbq('init', key_pixel)
      }
  }
}
 
function sendPageview() {
  //ReactPixel.pageView()
  window.fbq('track', 'PageView')
}

const pixelFacebook = {
	init,
    sendPageview
}
 
export default pixelFacebook