import ReactGA from "react-ga"
 
const key_google = process.env.REACT_APP_KEY_GOOGLE_ANALYTICS
 
function init() {
  // Enable debug mode on the local development environment
 // const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  ReactGA.initialize(key_google)
}
 
function sendPageview(path) {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}

const analytics = {
	init,
  sendPageview
}
 
export default analytics